@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main: #93C5FD;
    --main-light: #E3F0FF;
    --white: #ffffff;
    --black: #000000;
}

/* Reset some default styles */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;   
    color: var(--black);
}

p {
    font-size: 1.2rem;
}

.icon {
    max-height: 5rem;
    max-width: 5rem;
    padding: 10px;
    opacity: 0.7;
    transition: transform 0.2s, opacity 0.2s;
}

.icon-link {
    max-height: 2rem;
    max-width: 2rem;
    transition: transform 0.2s, opacity 0.2s;
}

.icon:hover, 
.icon-link:hover {
    transform: scale(1.2);
    opacity: 1;
}

.tech {
    overflow: hidden;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-right: 2vw;
    margin-top: 2vw;
    max-width: fit-content;
    background-color: var(--main-light);
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    position: relative;
}

.tech::before,
.tech::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}

.tech:after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
}

.tech:hover::after {
    left: 100%;
}

.content {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 10vh;
    padding: 2rem;
}

.about-content {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10vh;
    padding: 2rem;
}

footer {
    text-align: center;
    background-color: var(--main);
    color: var(--white);
    padding: 0.5rem;
}

.landing-page {
	height: 100vh;
    position: relative;
	display: flex;
    background-image: url("./assets/images/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -1;
}

.hello-image {
    display: none;
    transition: display .5s ease-in-out;
}

.about-me-image {
    border-radius: 50%;
    margin: 0 auto;
    max-height: 400px;
    overflow: hidden;
    aspect-ratio: 1;
}

.project-card {
   padding: 3rem;
   background-color: rgba(255, 255, 255, 0.9); 
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   margin-bottom: 3rem;
}

.experience-section {
    min-height: 60vh;
}

.experience-menu {
    min-width: 100px;
    padding: 10px;
    width: 100%;
    font-size: 1.2rem;
    background-repeat: no-repeat;
    text-align: left;
    border-left: 2px solid; 
    overflow: hidden;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.experience-menu:hover {
    background-color: var(--main-light);
}

.selected {
    color:  var(--black);
    background-color: var(--main-light);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.workdates{
	color: var(--main);
    font-size: 1.2rem;
	font-style: italic;
	padding-bottom: 2vh;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
    .experience-menu {
        height: 100%;
        font-size: 1rem;
        border-left: none;
        border-radius: 0;
        border-bottom: 2px solid; 
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .selected {
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .experience-list {
        display: flex;
        overflow-x: auto;
        width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-bottom: 2vh;
    }

    .experience-list::-webkit-scrollbar {
        display: none;
    }

    .experience-section {
        min-height: 80vh;
    }

    .icon {
        max-height: 4rem;
        max-width: 4rem;
        padding: 10px;
    }

    .project-card {
        padding: 5vw;
    }

    p {
        font-size: 1rem;
    }
}
